// This is the "loader animation"

@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';

.loader {
  @include centerRow;
    position: fixed;
    margin-top: -15rem;
    margin-left: -8px;
    
    background: linear-gradient($secondary-color, $main-color);
    height: 120%;
    width: 100%;
    z-index: 10;
    gap: 9px;
    font-size: 3.2rem;
    animation: load 2s forwards;
    text-shadow: $tertiary-color 1px 0 10px;
    color: $white;
    & .bar1, .bar2, .bar3 {
      box-shadow: $tertiary-color 1px 0 10px;
      height: 50px;
      width: 20px;
      background-color: $white;
      animation: grow 1.5s infinite;
    }
    & .bar2 {
      animation-delay: 0.3s;
    }
    & .bar3 {
      animation-delay: 0.6s;
    }
}

@keyframes grow {
  0% {transform: scaleY(0); opacity: 0;}
  50% {transform: scaleY(1); opacity: 1;}
  100% {transform: scaleY(0); opacity: 0;}
}

@keyframes load {
  0% {transform: scale(1); opacity: 1;}
  50% {transform: scale(1); opacity: 1;}
  100% {transform: scale(1); opacity: 0; visibility: hidden;}
}

@include forDesktop {
  .loader {
    margin-top: -13rem;
    height: 100%;
  }
}