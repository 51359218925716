@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';


.front-page {
  @include centerColumn;
  position: relative;
  z-index: 2;
  font-family: $main-font;
  margin-top: 10rem;
  // backdrop-filter: blur(5px);
  @include animation;
  & h1 {
    font-size:45px ;
    color: $white;
    margin: 1rem;
  }
  &__title-span {
  
    color: $light-purple;
  }
  & h2 {
    color: $grey;
    font-size: 20px;
    margin: 1rem;
    margin-top: 1rem;
  }
  & h3 {
    color: $white;
    margin-top: 2rem;
    font-size: 22px
  }

  & button {
    @include Bigbutton;
    margin-top: 2rem;
    font-weight: bold;
  }
}

@include forTablet {
  .front-page {
    display: flex;
    align-items: flex-start;
    margin-left: 5rem;
    margin-top: 1çrem;

    & h1 {
      font-size: 60px;
    }

    & h2 {
      font-size: 25px;
      margin-top: 3rem;
    }

    & h3 {
      margin-left: 1rem;
      font-size: 25px;
      margin-top: 3rem;
    }

    & button {

      width: 250px;
      height: 50px;
      margin-top: 5rem;
      margin-left: 10rem;
    }
  }
}

@include forDesktop {
  .front-page {
    display: flex;
    align-items: flex-start;
    margin-left: 5rem;
    margin-top: 13rem;

    & h1 {
      font-size: 80px;
    }

    & h2 {
      font-size: 35px;
    }

    & h3 {
      margin-left: 1rem;
      font-size: 35px;
      margin-top: 1rem;
    }

    & button {
      width: 250px;
      height: 60px;
      margin-top: 2rem;
    }
  }
}

