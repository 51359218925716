@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';


.video-container {
  width: 100%;
  height: 200px;
  display: flex;
  position: fixed;  //absolute
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  overflow: hidden;

  &__video {
    position: fixed;
    width: 100%;
    height: 100%;
    top: -280px;
    left: -5px;
    object-fit: cover;
    z-index: -1;
    background-color: $main-color;
  }
}

@include forTablet {
  .video-container {
    &__video {
      top: -250px;
      left: -9px;
    }
  }
}

@include forDesktop {
  .video-container{
    &__video {
      top: -250px;
      left: -9px;
    }
  }
}

