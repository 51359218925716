@import '../../sass/utilities/_variables.scss';
@import '../../sass/utilities/_mixins.scss';

.about {
  margin-left: -8px;
  margin-right: -8px;
  z-index: 3;
  margin-top: 2rem;
  @include animation;
  &__section-container {
    @include centerColumn;
    height: 100%;
      width: 100%;
  }
  &__left-section {
    height: 100%;
    width: 100%;
    @include centerColumn;
  }
  &__name {
    color: $white;
    text-shadow: $tertiary-color 1px 0 10px;
    font-size: 30px;
    font-weight: bold;
    margin-top: 2rem;
  }
  &__profile-pic {
    height: 270px;
    width: 300px;
    object-fit: cover;
    border-radius: 3rem;
  }
  &__right-section {
    color: $grey;
    margin-top: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 20px;
    background-color: $dark-purple-color;
    padding: 2rem;
    border-radius: 5px;
    border: solid;
    border-width: 3px;
    border-color: $light-purple;
    color: $white;
    line-height: 1.4;
  }

  &__subtitle {
    font-size: 28px;
  }
  &__span {
    color: $light-purple;
    font-size: 22px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  &__span-fr {
    color: $blue;
  }
  &__span-an {
    color: $white;
  }
  &__span-ce {
    color: $red;
  }

  &__list-title {
    margin-bottom: 0;
  }

  &__list {
    margin-top: 0;
  }
}

@include forTablet {
  .about{
    & h2 {
      font-size: 40px;
    }
  }
}

@include forDesktop {
  .about {
    margin-top: 6rem;
    &__section-container {
      // @include centerRow;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
    &__profile-pic {
      height: 400px;
      width: 360px;
      margin-left: 5rem;
    }
    &__name {
      font-size: 40px;
      margin-left: 5rem;
    }
    &__right-section {
      margin-top: 8rem;
      margin-right: 10rem;
      width: 1400px;
    }

    &__left-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
  }
}