@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';



.contact {
  @include centerColumn;
  height:715px;
  @include animation;

  & h2 {
    color: $white;
    font-size: 30px;
    text-shadow: $tertiary-color 1px 0 10px;
    margin-bottom: 3rem
  }

  &__form {
    @include centerColumn;
    height: 550px;
  }

  &__container {
    @include centerColumn;
  }

  &__name {
    color: $white;
    text-shadow: $tertiary-color 1px 0 10px;
    font-size: 25px;
    margin-top: 3rem;
  }

  &__name-input {
    width: 250px;
    @include border;
    height: 2rem;
    font-size: 16px;
    margin-top: 5px;
    background: $dark-purple-color;
    color: $white;

    &:focus {
      @include glow-effect;
    }
  }

  &__email {
    color: $white;
    margin-top: 1rem;
    text-shadow: $tertiary-color 1px 0 10px;
    font-size: 25px;
  }

  &__email-input {
    width: 250px;
    @include border;
    height: 2rem;
    font-size: 16px;
    margin-top: 5px;
    background: $dark-purple-color;
    color: $white;

    &:focus {
      @include glow-effect;
    }
  }

  &__message {
    color:$white;
    margin-top: 1rem;
    font-size: 25px;
    text-shadow: $tertiary-color 1px 0 10px;
  }

  &__message-text-area {
    margin-top: 5px;
    width: 300px;
    @include border;
    font-size: 16px;
    background: $dark-purple-color;
    color: $white;

    &:focus {
      @include glow-effect;
    }
  }
  
  &__submit {
    @include Bigbutton ;
    @include centerColumn;
    margin-top: 1rem;
    padding-bottom: 3rem
  }

  &__success-message {
    @include border;
    height: 2rem;
    font-size: 20px;
    border-radius: 50px;
    padding: 1rem;
    background: $dark-purple-color;
    color: $green;
    border-color: $green;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .contact__success-message.visible {
    opacity: 1;
    visibility: visible;
  }

  &__submit-container {
    @include centerColumn;
    margin: 1rem;
  }
}

@include forTablet {
  .contact {
    height:715px
    & h2 {
      font-size: 40px;
    }

    &__name-input {
      width: 300px;
    }

    &__email-input {
      width: 300px;
    }

    &__message-text-area {
      width: 450px;
    }
    
  }
}

@include forDesktop {
  .contact {
    align-items: flex-start;
    margin-left: 4rem;
    height: 360px;
    width: 1200px;

    & h2 {
      font-size: 80px;
      margin-left: 2rem;
      margin-top: 1rem;
    }

    &__form {
      @include centerRow;
      align-items: flex-start;
      margin-top: -4rem;
      width: 1350px;
    }
      &__name {
        margin-top: 2rem;
      }

      &__message-text-area {
        width: 600px;
        height: 180px;
      }

      & .name-email{
        margin-left: 5rem;
      }

      & .message-submit {
        margin-left: 120px;
      }

      &__submit {
        margin-top: 1rem;
      }
      &__success-message {
        @include border;
        width: 275px;
        font-size: 22px;
        border-radius: 50px;
        padding: 1rem;
        background: $dark-purple-color;
        border-color: $green;
      }
      &__submit-container {
        @include centerColumn;
        margin: 1rem;
        margin-left: 3rem;
        height: 200px;
      }
  }
}