@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';


  .skills-section {
    @include centerColumn;
    height: 500px;
    width: 100%;
    margin-top: 2rem;
    @include animation;

    & h2 {
      color: $white;
      font-size: 30px;
      text-shadow: $tertiary-color 1px 0 10px;
      margin-top: 2rem;
    }

    & h3 {
      color: $white;
    }

    &__container {
      @include spaceBetween;
      width: 320px;
      height: 100px;

      &__img {
        height: 50px;
        width: 60px;
        object-fit: contain;
      }
    }
  }

  @include forDesktop {
    .skills-section{
      & h2 {
        font-size: 40px;
      }

      & h3 {
        font-size: 35px;
      }

      &__container {
        width: 620px;
        margin-bottom: 5rem;
        &__img {
          height: 100px;
          width: 120px;
        }
      }
    }
  }

  @include forTablet {
    .skills-section{
      & h2 {
        font-size: 40px;
      }

      & h3 {
        font-size: 25px;
      }

      &__container {
        width: 620px;
        margin-bottom: 5rem;
        &__img {
          height: 80px;
          width: 100px;
        }
      }
    }
  }