@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';

.footer {
  height: 10rem;
  background: $secondary-color;
  color: $main-color;
  @include centerColumn;
  position: relative;
  z-index: 3;
  margin: -8px;
  margin-bottom: -8px;
}