$main-font:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

$secondary-color: #030014;
$main-color: #5327c1;
$tertiary-color: rgb(240, 240, 240);
$dark-purple-colors: #2A0E61 ;

$white: white;
$grey: rgb(130, 127, 127);
$dark-grey:rgb(74, 81, 101);
$light-purple: #7927c1;
$dark-purple-color: #110239;
$green: #0be4aa;
$blue: #0b1de4;
$red: #e40b2f;;


$text-color:$tertiary-color


