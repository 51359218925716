@import '../../sass/utilities/variables.scss';
@import '../../sass/utilities/mixins.scss';



  .stack-section {
    @include centerColumn;
    height: 100%;
    width: 100%;
    margin-top: 5rem;
    background-color: aqua;

    &__container {
      @include centerRow;
      gap: 5px;
      width: 250px;
      height: 50px;

      &__img {
        height: 30px;
        width: 40px;
        object-fit: contain;
      }
    }
  }
